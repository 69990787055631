import React from "react";
import { Steps, Step } from "react-step-builder";
import AboutMe from "./AboutMe";
import Contact from "./Contact";
import Waiver from "./Waiver";
import CampStudent from "./User Types/CampStudent";
import CodingStudent from "./User Types/CodingStudent";
import Staff from "./User Types/Staff";
import Visitor from "./User Types/Visitor";
import Resident from "./User Types/Resident";
import ConfirmInfo from "./ConfirmInfo";

export default function SignUp(props) {

    var { userType } = props.location.state || localStorage.getItem('userType');
        // setter
    localStorage.setItem('userType', userType);
    localStorage.getItem('userType');

    const components = {
        'Resident': Resident,
        'Visitor': Visitor,
        'Camp Student': CampStudent,
        'Staff': Staff,
        'Coding Student': CodingStudent
    };
    var UserComponent = components[userType] 
    return (
        <div>
            <Steps>
                <Step title="About Me" component={AboutMe} />
                <Step title="Contact Info" component={Contact} />
                <Step title="Additional Info" component={UserComponent} />
                <Step title="Waiver" component={Waiver} />
                <Step title="Confirm" component={ConfirmInfo} />
            </Steps>
        </div>
    );
}