import * as bs from 'react-bootstrap';
import {React, useState} from "react";
import states from "../../states.json";
import NumberFormat from 'react-number-format';

export default function Contact(props) {
    const [validated, setValidated] = useState(false);

    const RelationOptions=[
        {label: "Aunt/Uncle", key: "Aunt/Uncle"}
      , {label: "Cousin", key: "Cousin"}
      , {label: "Grandparent", key: "Grandparent"}
      , {label: "Guardian", key: "Guardian"}
      , {label: "Parent", key: "Parent"}
      , {label: "Sibling", key: "Sibling"}
      , {label: "Spouse", key: "Spouse"}
      , {label: "Other", key: "Other"}
    ];

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
      props.next()
    };
  
    return (
        <div>
        
            <bs.Container>
                <ul className="list-unstyled multi-steps">
                    <li key="aboutMe" onClick={props.prev} style={{cursor: 'pointer'}}>About Me</li>
                    <li key="contact" className="is-active">Contact</li>
                    <li key="otherInfo">Other Info</li>
                    <li key="waiver">Waiver</li>
                    <li key="confirm">Confirm</li>
                </ul>
                <bs.Form validated={validated} onSubmit={handleSubmit}>
                    <br></br>
                    <h5>Your Contact Info</h5>            
                    <hr></hr>
                    <bs.Form.Group controlId="AddressLine1">
                        <bs.Form.Label>Address Line 1</bs.Form.Label>
                        <bs.Form.Control
                            name="Address1"
                            type="text"
                            placeholder="Address Line 1"
                            maxLength = "35"
                            minLength = "5" 
                            required
                            value={props.getState("Address1", "")}
                            onChange={props.handleChange}/>
                    </bs.Form.Group>
                    <bs.Form.Group controlId="AddressLine2">
                        <bs.Form.Label>Address Line 2 (Optional)</bs.Form.Label>
                        <bs.Form.Control
                            type="text"
                            name="Address2"
                            placeholder="Address Line 2"
                            value={props.getState("Address2", "")}
                            onChange={props.handleChange}/>
                    </bs.Form.Group>
                    <bs.Form.Row>
                        <bs.Col>
                            <bs.Form.Group controlId="City">
                                <bs.Form.Label>City</bs.Form.Label>
                                <bs.Form.Control
                                    type="text"
                                    name="City"
                                    maxLength = "20"
                                    minLength = "2" 
                                    pattern = "^[A-Za-z ]+$"
                                    required
                                    value={props.getState("City", "Atlanta")}
                                    onChange={props.handleChange}/>
                            </bs.Form.Group>
                        </bs.Col>
                        <bs.Col>
                            <bs.Form.Group controlId="State">
                                <bs.Form.Label>State</bs.Form.Label>
                                <bs.Form.Control
                                    as="select"
                                    name="State"
                                    required
                                    value={props.getState("State", "GA")}
                                    onChange={props.handleChange}>
                                        {states.map(item => {
                                            return(
                                                <option value={item.abbreviation}>{item.name}</option>)})}
                                </bs.Form.Control>
                            </bs.Form.Group>
                        </bs.Col>
                        <bs.Col>
                            <bs.Form.Group controlId="ZipCode">
                                <bs.Form.Label>Zip Code</bs.Form.Label>
                                <bs.Form.Control
                                    type="text"
                                    placeholder="Zip Code"
                                    maxLength = "5"
                                    minLength = "5" 
                                    pattern = "^[0-9]*$"
                                    name="ZipCode"
                                    required
                                    value={props.getState("ZipCode", "")}
                                    onChange={props.handleChange}/>
                            </bs.Form.Group>
                        </bs.Col>
                    </bs.Form.Row>
                    <bs.Form.Row>
                        <bs.Col>
                            <bs.Form.Group controlId="Phone">
                                <bs.Form.Label>Phone</bs.Form.Label>
                                <NumberFormat
                                    type = "text"
                                    format="(###) ###-####"
                                    name="Phone"
                                    pattern = "^\(\d\d\d\) \d\d\d-\d\d\d\d$"
                                    required
                                    className="form-control"
                                    placeholder="Phone"
                                    value={props.getState("Phone", "")}
                                    onChange={props.handleChange}
                                />
                            </bs.Form.Group>
                        </bs.Col>
                    </bs.Form.Row>
                    <br></br>
                    <h5>Emergency Contact Info</h5>
                    <hr></hr>
                    <bs.Form.Row>
                        <bs.Col>
                            <bs.Form.Group controlId="EmergencyContactFirstName">
                                <bs.Form.Label>First Name</bs.Form.Label>
                                <bs.Form.Control
                                    type="text"
                                    maxLength = "20"
                                    minLength = '2' 
                                    pattern = "^[A-Z][A-z-'\p{L}\p{M}]+$" //First letter capital. Only letters, hyphens, and apostraphes. And international letters
                                    placeholder="First"
                                    name="EmergencyContactFirstName"
                                    required
                                    value={props.getState("EmergencyContactFirstName", "")}
                                    onChange={props.handleChange}/>
                                <bs.Form.Text id="EmergencyFirstNameHelpBlock" muted>
                                First name must be captilized.
                            </bs.Form.Text>
                            </bs.Form.Group>
                        </bs.Col>
                        <bs.Col>
                            <bs.Form.Group controlId="EmergencyContactLastName">
                                <bs.Form.Label>Last Name</bs.Form.Label>
                                <bs.Form.Control
                                    type="text"
                                    maxLength = "20"
                                    minLength = '2' 
                                    pattern = "^[A-Z][A-z-'\p{L}\p{M}]+$" //First letter capital. Only letters, hyphens, and apostraphes. And international letters
                                    placeholder="Last"
                                    name="EmergencyContactLastName"
                                    required
                                    value={props.getState("EmergencyContactLastName", "")}
                                    onChange={props.handleChange}/>
                                    <bs.Form.Text id="EmergencyLastNameHelpBlock" muted>
                                Last name must be captilized.
                            </bs.Form.Text>
                            </bs.Form.Group>
                        </bs.Col>
                    </bs.Form.Row>
                    <bs.Form.Group controlId="EmergencyContactEmail">
                                <bs.Form.Label>Email Address</bs.Form.Label>
                                <bs.Form.Control
                                    type="email"
                                    name="EmergencyContactEmail"
                                    placeholder="Email" //jdoe@atlantapublicschools.us
                                    pattern = "^\S+@\S+\.\S+$"
                                    required
                                    value={props.getState("EmergencyContactEmail", "")}
                                    onChange={props.handleChange}/>
                    </bs.Form.Group>
                    <bs.Form.Row>
                        <bs.Col>
                            <bs.Form.Group controlId="EmergencyContactPhone">
                                <bs.Form.Label>Phone</bs.Form.Label>
                                <NumberFormat
                                    format="(###) ###-####"
                                    mask=""
                                    name="EmergencyContactPhone"
                                    required
                                    className="form-control"
                                    pattern = "^\(\d\d\d\) \d\d\d-\d\d\d\d$"
                                    placeholder="Phone"
                                    value={props.getState("EmergencyContactPhone", "")}
                                    onChange={props.handleChange}/>
                            </bs.Form.Group>
                        </bs.Col>
                        <bs.Col>
                            <bs.Form.Group controlId="EmergencyContactRelationship">
                                <bs.Form.Label>Relationship to You</bs.Form.Label>
                                <bs.Form.Control
                                    as="select"
                                    required
                                    name="EmergencyContactRelationship"
                                    value={props.getState("EmergencyContactRelationship", "")}
                                    onChange={props.handleChange}
                                    >
                                    <option></option>
                                    {RelationOptions.map(item => {
                                        return(
                                            <option value={item.key}>{item.label}</option>)})}
                                </bs.Form.Control>
                            </bs.Form.Group>
                        </bs.Col>
                    </bs.Form.Row>
                    <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                        <bs.Col className="col-3">
                            <bs.Button type="primary" className="NextButton" onClick={props.prev} style={{ float: 'left' }}>
                                &#8592; Back
                            </bs.Button>
                        </bs.Col>
                        <bs.Col/>
                        <bs.Col className="col-6">
                            <bs.Button type="submit" className="NextButton" style={{ float: 'right' }}>
                                Next &#8594;
                            </bs.Button>
                        </bs.Col>
                    </bs.Form.Row>
                </bs.Form>
            </bs.Container>
        </div>
    )
}