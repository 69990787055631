import * as bs from 'react-bootstrap';
import {React, useState} from "react";

export default function CodingStudent(props) {
    const [validated, setValidated] = useState(false);


    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
      props.setState("userType",'Coding Student')
      props.next()
    };
  
    return (
        <div>
            <bs.Container>
                <ul className="list-unstyled multi-steps">
                    <li key="aboutMe">About Me</li>
                    <li key="contact" onClick={props.prev} style={{cursor: 'pointer'}}>Contact</li>
                    <li key="otherInfo" className="is-active">Other Info</li>
                    <li key="waiver">Waiver</li>
                    <li key="confirm">Confirm</li>
                </ul>
                <bs.Form validated={validated} onSubmit={handleSubmit}>
                    <h5>Coding Student</h5>
                        <bs.Form.Group controlId="CodingStudentCurrentSchool">
                            <bs.Form.Label>Current School</bs.Form.Label>
                            <bs.Form.Control
                                type="text"
                                name="CodingStudentCurrentSchool"
                                maxLength = "30"
                                minLength = "5" 
                                pattern = '^[A-Z][A-z]+(?:\s[A-Z][A-z]+)*$'
                                placeholder="i.e. Jefferson High School"
                                required
                                value={props.getState("CodingStudentCurrentSchool", "")}
                                onChange={props.handleChange}/>
                            <bs.Form.Text id="CodingStudentSchoolNameHelpBlock" muted>
                                School name must be three separate words and captilized
                            </bs.Form.Text>
                        </bs.Form.Group>
                        <bs.Form.Group controlId="CodingClass">
                            <bs.Form.Label>Which Coding Class?</bs.Form.Label>
                            <bs.Form.Control
                                as="select"
                                name="CodingClass"
                                required
                                value={props.getState("CodingClass", "")}
                                onChange={props.handleChange}
                                >
                                 <option></option>
                                <option value="Intro Computer Science">Introduction to Computer Science</option>
                                <option value="Other">Other</option>
                            </bs.Form.Control>
                        </bs.Form.Group>
                        <bs.Form.Group controlId="CodingClassReference">
                            <bs.Form.Label>How did you hear about this class?</bs.Form.Label>
                            <bs.Form.Control
                                as="select"
                                name="CodingClassReference"
                                required
                                value={props.getState("CodingClassReference", "")}
                                onChange={props.handleChange}>
                                <option></option>
                                <option value="Friend">Friend</option>
                                <option value="School">School</option>
                                <option value="Robotics Team">Robotics team</option>
                                <option value="Online">Online</option>
                                <option value="Presentation">A Johnson STEM Presentation</option>
                                <option value="Social Media">Social Media</option>
                                <option value="Visiting">Visiting the facility</option>
                                <option value="Word of Mouth">Word of mouth (coaches, teachers, prior students)</option>
                                <option value="JSAC Website">JSAC Website</option>
                            </bs.Form.Control>
                        </bs.Form.Group>
                    <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                        <bs.Col className="col-3">
                            <bs.Button type="primary" className="NextButton" onClick={props.prev} style={{ float: 'left' }}>
                                &#8592; Back
                            </bs.Button>
                        </bs.Col>
                        <bs.Col/>
                        <bs.Col className="col-6">
                            <bs.Button type="primary" className="NextButton" style={{ float: 'right' }}>
                                Next &#8594;
                            </bs.Button>
                        </bs.Col>
                    </bs.Form.Row>
                </bs.Form>
            </bs.Container>
        </div>
    )
}