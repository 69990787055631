import * as bs from 'react-bootstrap';
import {React} from "react";
import {Link } from "react-router-dom";
import robot from '../../JSAC robot-01 1.png';

function CheckInSuccess(props) {

    setTimeout(function(){
        window.location.href = 'https://jstemreach.com/checkIn';
     }, 3000);
    return(
        <div>
            <br></br>
            <iframe src='https://www.youtube.com/embed/qZC5gtOw3DU?autoplay=1'
             frameBorder='0'
            allow='autoplay; encrypted-media'
            hidden
            title='video'
/>
            <div className = "CheckInCheckOut">
                <h3>Welcome, {props.location.state.name}!</h3>
                <h6>We're glad to see you.</h6>
            </div>
            <br></br>
            <Link to ="/CheckIn">
                <div className="buttonDiv">
                <bs.Button variant="primary" type="submit" className="NextButton" >
                    Finish
                </bs.Button>
                </div>
            </Link>
            <img
             src= {robot}
             width="400"
             alt="jstem robot"
         />{' '}
        </div>

        )  
}

export default (CheckInSuccess)