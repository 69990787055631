import * as bs from 'react-bootstrap';

const popover = (
  <bs.Popover id="difference">
      <bs.Popover.Title as="h2">What's the Difference?</bs.Popover.Title>
      <bs.Popover.Content>
        <div>
            <b>Resident Student</b> <p>A regular user of the JSTEM facilities and a member of a J-STEM team</p>
            <b>Camp Student</b> <p>A student that comes to J-STEM seasonally for a fixed number of weeks</p>
            <b>Coding Student</b> <p>A student that is attending a J-STEM coding class (and isn't a resident)</p>
            <b>Staff</b> <p>An administrator, coach, mentor, or employee of the J-STEM Facility</p>
            <b>Visitor</b> <p>A Parent/Guardian, here for touring, scrimmage, or non-resident facility use</p>
        </div>
      </bs.Popover.Content>
  </bs.Popover>

);
  
  const DifferenceButton = () => (
    <bs.OverlayTrigger trigger="click" placement="left" overlay={popover}>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="14" cy="14" r="13" fill="white" stroke="#2B4E77" strokeWidth="2"/>
          <path d="M14.28 16.74V14.72C16.06 14.38 17.92 13.14 17.92 10.64C17.92 8.32 16.3 6.8 13.78 6.8C11.24 6.8 9.92 8.34 9.42 10.04L11.08 10.68C11.4 9.4 12.28 8.46 13.72 8.46C15.04 8.46 15.94 9.28 15.94 10.58V10.86C15.94 12.5 14.66 13.42 12.5 13.5V16.74H14.28ZM13.36 21.24C14.28 21.24 14.68 20.72 14.68 20V19.7C14.68 18.98 14.28 18.46 13.36 18.46C12.44 18.46 12.04 18.98 12.04 19.7V20C12.04 20.72 12.44 21.24 13.36 21.24Z" fill="#2B4E77"/>
        </svg>
    </bs.OverlayTrigger>
  );

  export default(DifferenceButton)