import * as bs from 'react-bootstrap';
import { Link } from "react-router-dom";
import {React, useState} from "react";
import { Redirect } from "react-router-dom";
import firebase from '../../Firebase.js';

function CheckIn() {
    var db = firebase.firestore();
    var rtdb = firebase.database();
    var visits = db.collection("visits");
    var users = db.collection("users");
    const [userName, setUserName] = useState(null);
    const [shouldRedirectIn, setShouldRedirectIn] = useState(false);
    const [shouldRedirectOut, setShouldRedirectOut] = useState(false);
    const handleSubmitCheckIn = () => {
        var email = document.getElementById('Email').value;
        var userData;
        if(email.length > 1){
        var docRef = users.doc(email)
        docRef.get().then((doc) => {
            if (doc.exists) {
                userData = doc.data();
                if (userData.checkedIn === false) {
                    setUserName(userData.firstName)
                    var timeIn = new Date();

                    rtdb.ref('visits').push().set({
                        type: 'In',
                        timestamp: timeIn.toLocaleString("en-US"),
                        userID: userData.userUid,
                        email: email
                    })

                    visits.add({
                        timeIn: timeIn.toLocaleString("en-US"),
                        timeOut: "",
                        userID: userData.userUid,
                        email: email
                    })
                    docRef.update({
                        checkedIn: true
                    })
                    .then(() => {
                        setShouldRedirectIn(true);
                    })  
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
                }
                else {
                    alert("User is already checked in")
                }
            }
            else {
                document.getElementById('Email').value = ''
                alert("This email is not tied to an account, please create an account before signing in")
            }
        })
    }
    else{
        alert('Please input an email before checking in')
    }

};
    
    const handleSubmitCheckOut = () => {
        var email = document.getElementById('Email').value;
        if(email.length > 1){
            var docRef = users.doc(email);
            var userData;
            var checkOutQuery = visits.where("email", "==", email).where("timeOut", "==", "");
            docRef.get().then((doc) => {
                if (doc.exists) {
                    console.log("doc exists")
                    userData = doc.data();
                    if (userData.checkedIn === true) {
                        var timeOut = new Date();
                        console.log("user is checked in")
                        setUserName(userData.firstName)

                        rtdb.ref('visits').push().set({
                            type: 'Out',
                            timestamp: timeOut.toLocaleString("en-US"),
                            userID: userData.userUid,
                            email: email
                        })

                        checkOutQuery.get().then((querySnapshot) => {
                            querySnapshot.forEach((doc) => {
                                var docId = doc.id;
                                visits.doc(docId).update({
                                    timeOut: timeOut.toLocaleString("en-US")
                                })
                            })
                        })
                        docRef.update({
                            checkedIn: false
                        })
                        .then(() => {
                            setShouldRedirectOut(true);
                        })  
                        .catch((error) => {
                            console.error("Error writing document: ", error);
                        });  
                    } 
                    else {
                        alert("User is not checked in yet")
                    }
                } else {
                    document.getElementById('Email').value = ''
                    alert("This email is not tied to an account, please create an account before signing in")
                }
            })
        }
        else{
            alert('Please input an email before checking out')
        }
    };

    return(
        <div>
            <bs.Form>
                <div className = "CheckInCheckOut">
                    <h3>Welcome to Johnson STEM!</h3>
                    <h6>Please check in before entering</h6>
                </div>
                    <bs.Form.Group controlId="Email">
                        <bs.Form.Label>Email</bs.Form.Label>
                        <bs.Form.Control type="email" placeholder="Email..." required/>
                    </bs.Form.Group>
                <Link to ="/">
                    <p className = "MakeanAccount">Make an account</p>
                </Link>
                <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                    <bs.Col className="col-6">
                        <bs.Button variant="primary" onClick={handleSubmitCheckIn} className="NextButton" >
                            Check In
                        </bs.Button>
                    </bs.Col>
                    <bs.Col className="col-6">
                        <bs.Button variant="primary" onClick={handleSubmitCheckOut} className="NextButton" >
                            Check Out
                        </bs.Button>
                    </bs.Col>
                </bs.Form.Row>
            </bs.Form>
            {shouldRedirectIn && (
                <Redirect push
                to={{
                    pathname: "/CheckInSuccess",
                    state: { name: userName }
                  }}/>
            )}
            {shouldRedirectOut && (
                <Redirect push
                to={{
                    pathname: "/CheckOutSuccess",
                    state: { name: userName }
                  }}/>
            )}
        </div>
        )  
    };

export default (CheckIn)
