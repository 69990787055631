import * as bs from 'react-bootstrap';
import {React, useState} from "react";
import moment from 'moment';

export default function Waiver(props) {
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        setValidated(true);
        props.next()
      };

      var adultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
      var parts = props.state.Birthdate.split('-');
      var Birthdate = new Date(parts[0], parts[1] - 1, parts[2]);
      
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;

    return(
        
        <div>
            <bs.Container>
                <ul className="list-unstyled multi-steps">
                    <li key="aboutMe">About Me</li>
                    <li key="contact">Contact</li>
                    <li key="otherInfo" onClick={props.prev} style={{cursor: 'pointer'}}>Other Info</li>
                    <li key="waiver" className="is-active">Waiver</li>
                    <li key="confirm">Confirm</li>
                </ul>
                <div id="WaiverText">
                    <h2>
                    Release and Hold Agreement
                    </h2>
                    <p>
                    This Release and Hold Harmless Agreement (“Release”) pertains to <br></br><b>{props.state.FirstName} {props.state.LastName}</b> (“Participant”) and the Participant’s involvement in activities related to the Johnson STEM Activity Center Robotics Program (“Program”).
                    </p>
                    <p>
                    If Participant is less than 18 years of age or has a legal guardian, then this release is signed by Participant’s parent or legal guardian (such parent or legal guardian being referred to herein after as “Guardian”), on behalf of and binding on Guardian and Participant, and Guardian gives permission for Participant to participate and engage in all activities related to the Program, including all activities at or relating to the facilities of Johnson STEM Activity Center, as defined below.  
                    </p>
                    <p>
                    The undersigned hereby forever waives and releases, to the full extent permitted by law, Lonnie G. Johnson, Johnson STEM  and Johnson Research and Development Company, Inc., Johnson Battery Technologies, Inc., Johnson Electro-Mechanical Systems, LLC, Johnson Thermo-Electrochemical Converter Energy, Inc., and Johnson Real Estate Investments, LLC, and all of their respective employees and agents (hereinafter referred to collectively as “Johnson STEM”) from any and all liability, claims, demands, injuries, damages and causes of action arising from or relating to Participant’s activities in connection with the Program and/or facilities of Johnson STEM located at 263 or 275 Decatur Street, Atlanta, Georgia 30312 (“Activities”). The undersigned is fully aware of the risks involved in such Activities, voluntarily accepts all risks, and releases Johnson STEM from all liability relating to any accident, mishap or negligence of Johnson STEM or any other person or entity. The undersigned will hold Johnson STEM harmless from and indemnify Johnson STEM for any liability, claims, expenses and fees, including litigation fees and reasonable attorneys’ fees, arising from or relating to Participant’s Activities that result in actual or alleged claims, property damage or personal injury. This Release is given in consideration and as a condition of Johnson STEM’s permission (subject to revision at any time) for Participant to use its facilities as a license for Activities in connection with the Program at such times and locations as Johnson STEM may permit. Participant furthermore understands and hereby affirms that access to any Johnson STEM facility is a privilege which may be revoked at any time at the sole discretion of Johnson STEM.
                    </p>
                    <p>
                    This Release is binding on Participant and Guardian (if any) and their respective heirs, executors, administrators, assigned next of kin and persons having standing to recover on account of Participant’s injury or death, and shall insure to the benefit of Johnson STEM, its successors and assigns. The undersigned understands that the Activities Participant will be involved in are inherently dangerous and may cause serious injuries, including bodily injury, damage to personal property, and/or death. The undersigned assumes the risk and waives any and all claims against Johnson STEM of personal injury, including severe bodily injury, damage to personal property and/or Guardian death related to the Activities. 
                    </p>
                    <p>
                    Furthermore, Participant and Guardian understand that under Georgia law, there is no liability for injury or death of Participant entering the premises of Johnson STEM if such injury or death results from the inherent risk of contracting Covid 19.  Participant and Guardian agree to assume these risks when entering the premises of Johnson STEM. 
                    </p>
                    <p>
                    The undersigned represents and warrants that Participant does not have physical limitations, medical ailments, physical or mental disabilities that would limit or prevent Participant from participating in the Activities. 
                    </p>
                    <p>
                    I HAVE READ AND AGREE WITH THE TERMS OF THE RELEASE. I UNDERSTAND THAT BY CheckInG THIS RELEASE I HAVE GIVEN UP FUTURE LEGAL RIGHTS. I HAVE SIGNED THIS WAIVER FREELY, VOLUNTARILY, AND UNDER NO DURESS OR THREAT OF DURESS. MY SIGNATURE IS PROOF OF MY INTENTION TO EXECUTE A COMPLETE AND UNCONDITIONAL WAIVER AND RELEASE OF ALL LIABILITY TO THE FULL EXTENT OF THE LAW. I AM MENTALLY COMPETENT TO ENTER INTO SUCH AN AGREEMENT. I DECLARE THAT ALL FACTS STATED HEREIN ARE TRUE AND CORRECT. 
                    </p>
                    <p>
                    This Release is governed by and will be entrusted under the laws of the State of Georgia. The undersigned consents to the exclusive jurisdiction and venue of courts located in Atlanta, Georgia for the purpose of resolution of any claim or dispute arising from or relating to this Release.
                    </p>
                </div>

                <bs.Form validated={validated} onSubmit={handleSubmit}>
                    <hr></hr>
                    {((Birthdate <= adultDate))   
                        ? <h5>Your Info</h5>
                        : <h5>Adult</h5>}
                         <bs.Form.Text id="passwordHelpBlock" muted>
                                Anyone under the age of 18 will require a parent signature
                            </bs.Form.Text>
                    <hr></hr>
                    
                    <bs.Form.Group controlId="WaiverAdultName">
                        <bs.Form.Label>Full Name</bs.Form.Label>
                        <bs.Form.Control
                            type="text"
                            name="WaiverAdultName"
                            required
                            maxLength = "30"
                            minLength = "5" 
                            value={props.getState("WaiverAdultName", "")}
                            onChange={props.handleChange}/>
                    </bs.Form.Group>

                    <bs.Form.Row>
                        <bs.Col>
                            <bs.Form.Group controlId="WaiverAdultDate">
                                    <bs.Form.Label>Date</bs.Form.Label>
                                    <bs.Form.Control
                                        name="WaiverAdultDate"
                                        type="Date"
                                        min={moment().format("YYYY-MM-DD")}  //Must be today
                                        max={moment().format("YYYY-MM-DD")}    //Must be today
                                        required
                                        value={props.getState("WaiverAdultDate", "")}
                                        onChange={props.handleChange}/>
                            </bs.Form.Group>
                        </bs.Col>
                        <bs.Col>
                            <bs.Form.Group controlId="WaiverAdultCity">
                                <bs.Form.Label>City</bs.Form.Label>
                                <bs.Form.Control
                                    type="text"
                                    name="WaiverAdultCity"
                                    maxLength = "20"
                                    minLength = "2" 
                                    pattern = "^[A-Za-z ]+$"
                                    required
                                    value={props.getState("WaiverAdultCity", "")}
                                    onChange={props.handleChange}/>
                            </bs.Form.Group>
                        </bs.Col>
                    </bs.Form.Row>

                    <bs.Form.Group controlId="adultAgreeCheck">
                        <bs.Form.Check 
                        type="checkbox" 
                        label="By checking this box you acknowledge that you have read and agree to the terms stated in the above waiver on behalf of the child of which you are the parent or legal guardian" 
                        name="WaiverAdultAccept"
                        required
                        value={props.getState("WaiverAdultAccept","")}
                        onChange={props.handleChange}/>
                    </bs.Form.Group>
                    
                    {(Birthdate > adultDate) && 
                        <div>
                            <hr></hr>
                            <h5>Child</h5>
                            <hr></hr>
                            <bs.Form.Group controlId="WaiverChildName">
                                <bs.Form.Label>Full Name</bs.Form.Label>
                                <bs.Form.Control
                                    type="text"
                                    required
                                    maxLength = "30"
                                    minLength = "5" 
                                    name="WaiverChildName"
                                    value={props.getState("WaiverChildName", "")}
                                    onChange={props.handleChange}/>
                            </bs.Form.Group>
                            <bs.Form.Row>
                                <bs.Col>
                                    <bs.Form.Group controlId="WaiverChildDate">
                                        <bs.Form.Label>Date</bs.Form.Label>
                                        <bs.Form.Control
                                            name="WaiverChildDate"
                                            type="Date"
                                            min={moment().format("YYYY-MM-DD")}  //Must be today
                                            max={moment().format("YYYY-MM-DD")}    //Must be today
                                            required
                                            onChange={props.handleChange}
                                            value={props.getState("WaiverChildDate", "")}
                                            />
                                    </bs.Form.Group>
                                </bs.Col>
                                <bs.Col>
                                    <bs.Form.Group controlId="WaiverChildCity">
                                        <bs.Form.Label>City</bs.Form.Label>
                                        <bs.Form.Control
                                            type="text"
                                            name="WaiverChildCity"
                                            maxLength = "20"
                                            minLength = "2" 
                                            pattern = "^[A-Za-z ]+$"
                                            required
                                            value={props.getState("WaiverChildCity", "")}
                                            onChange={props.handleChange}/>
                                    </bs.Form.Group>
                                </bs.Col>
                            </bs.Form.Row>

                            <bs.Form.Group controlId="childAgreeCheck">
                                <bs.Form.Check
                                type="checkbox" 
                                label="By checking this box you acknowledge that you have read and agree to the terms stated in the above waiver" 
                                name="WaiverChildAccept"
                                required
                                value={props.getState("WaiverChildAccept","")}
                                onChange={props.handleChange}/>   
                            </bs.Form.Group>
                        </div>
                    }
                <bs.Form.Row align="center" style={{'textAlign': 'center'}}>
                    <bs.Col className="col-3">
                        <bs.Button type="primary" className="NextButton" onClick={props.prev} style={{ float: 'left' }}>
                            &#8592; Back
                        </bs.Button>
                    </bs.Col>
                    <bs.Col/>
                    <bs.Col className="col-6">
                        <bs.Button type="submit" className="NextButton" style={{ float: 'right' }}>
                            Next &#8594;
                        </bs.Button>
                    </bs.Col>
                </bs.Form.Row>
                </bs.Form>
            </bs.Container>
        </div>
    )
}