import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyDixok5bsl0YzFwGkonX5_9gtS1y8vJ4PY",
  authDomain: "jsac-reach.firebaseapp.com",
  projectId: "jsac-reach",
  databaseURL: "https://jsacreach.firebaseio.com/",
  storageBucket: "jsac-reach.appspot.com",
  messagingSenderId: "124105519457",
  appId: "1:124105519457:web:880fb6256d69d7fe201520"
};

firebase.initializeApp(firebaseConfig);

export default firebase;