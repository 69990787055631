import * as bs from 'react-bootstrap';
import logo from '../logoText.png';

function Header() {
    return(
        <bs.Navbar variant="light" expand="lg" className="m">
            <div className="col-2"></div>
            <bs.Navbar.Brand className="col-8 align-self-center center">
                <a href="/">
                    <img
                    src= {logo}
                    width="35%"
                    alt="jstem logo"
                    />{' '}
                </a>
            </bs.Navbar.Brand>
            {/* <bs.Button href="/LogIn" className="col-1" re>
                Sign In
            </bs.Button> */}

        </bs.Navbar>
)
}

export default (Header)