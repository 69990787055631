import * as bs from 'react-bootstrap';
import {Link } from "react-router-dom";
import robot from '../../JSAC robot-01 1.png';

function CheckOutSuccess(props) {

    setTimeout(function(){
        window.location.href = 'https://jstemreach.com/checkIn';
     }, 3000);
    return(
        <div>
            <br></br>
            <br></br>
            <br></br>
            <div className = "CheckInCheckOut">
                <h3>Thanks, {props.location.state.name}!</h3>
                <h6>See you next time.</h6>
            </div>
            <br></br>
            <br></br>
            <Link to ="/CheckIn">
                <div className="buttonDiv">
                <bs.Button variant="primary" type="submit" className="NextButton" >
                    Finish
                </bs.Button>
                </div>
            </Link>
            <img
             src= {robot}
             width="400"
             alt="jstem robot"
             style={{
                 "float": "right",
                "-webkit-transform": "scaleX(-1)",
                "transform": "scaleX(-1)"
             }}
         />{' '}
        </div>

        )  
}

export default (CheckOutSuccess)