import * as bs from 'react-bootstrap';
import {React} from "react";
import firebase from '../Firebase.js';

function ResetPassword() {

    const resetPassword = () => {

        var email = document.getElementById('Email').value;

        firebase.auth().sendPasswordResetEmail(email).then(function() {
            console.log("Email Sent")
            alert("Password Reset Email Sent")

            // Email sent.
          }).catch(function(error) {
              console.log(error)
            alert("Something Went Wrong. Check Your Email Address and Try Again")
          });
    };
    
    return(
        <div>
            <bs.Form>
                <div className = "ResetPassword">
                    <h3>Welcome to Johnson STEM!</h3>
                    <h6>To reset your password, enter your email below, press the button and follow the instructions in the email!</h6>
                </div>
                <bs.Form.Group controlId="Email">
                    <bs.Form.Label>Email</bs.Form.Label>
                    <bs.Form.Control type="email" placeholder="Email..." required/>
                </bs.Form.Group>
                <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                    <bs.Col className="col-12">
                        <bs.Button variant="primary" onClick={resetPassword} className="NextButton" >
                            Send Password Reset Email
                        </bs.Button>
                    </bs.Col>
                </bs.Form.Row>
            </bs.Form>
        </div>
        )  
    };

export default (ResetPassword)
