import * as bs from 'react-bootstrap';
import {React, useState} from "react";

export default function Resident(props) {
    const [validated, setValidated] = useState(false);
    
    

    const TeamOptions=[
        {label: "100 SRA (3091)", key: "3091"}
        , {label: "Atom (11967)", key: "11967"}
        , {label: "Atomic Bomb (6177)", key: "6177"}
        , {label: "Decatur Domination (14821)", key: "14821"}
        , {label: "Delta Overload (6068)", key: "6068"}
        , {label: "DiscBots (6023)", key: "6023"}
        , {label: "EVE (10458)", key: "10458"}
        , {label: "EVE (7514)", key: "7514"}
        , {label: "Fernbank LINKS (4468)", key: "4468"}
        , {label: "FS", key: "FS"}
        , {label: "Future Seekers 5 (219)", key: "219"}
        , {label: "Global Dynamics (4026)", key: "4026"}
        , {label: "Ignite Robotics (6829)", key: "6829"}
        , {label: "OTTO (1746)", key: "1746"}
        , {label: "TeknoSquad (5219)", key: "5219"}
        , {label: "Top Dawgs (44162)", key: "44162"}
    ];

    const ReferenceOptions=[
          {label: "Friend", key: "Friend"}
        , {label: "School", key: "School"}
        , {label: "Robotics team", key: "Robotics Team"}
        , {label: "Online", key: "Online"}
        , {label: "Johnson STEM Presentation", key: "Presentation"}
        , {label: "Social Media", key: "Social Media"}
        , {label: "Visit to the facility", key: "Visiting"}
        , {label: "Word of mouth (coaches, teachers, prior students)", key: "Word of Mouth"}
        , {label: "Johnson STEM Website", key: "Website"}

    ];

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
      props.setState("userType","Resident")
      props.next()
    };
  
    return (
        <bs.Container>
            <ul className="list-unstyled multi-steps">
                <li key="aboutMe">About Me</li>
                    <li key="contact"  onClick={props.prev} style={{cursor: 'pointer'}}>Contact</li>
                    <li key="otherInfo" className="is-active">Other Info</li>
                    <li key="waiver">Waiver</li>
                    <li key="confirm">Confirm</li>
            </ul>
            <bs.Form validated={validated} onSubmit={handleSubmit}>
                <h5>Resident</h5>
                    <bs.Form.Group controlId="ResidentCurrentSchool">
                        <bs.Form.Label>Current School</bs.Form.Label>
                        <bs.Form.Control 
                            type="text" 
                            name="ResidentCurrentSchool"
                            placeholder="i.e. Jefferson High School" 
                            maxLength = "30"
                            minLength = "5" 
                            pattern = '^[A-Z][A-z]+(?:\s[A-Z][A-z]+)*$'
                            required
                            value={props.getState("ResidentCurrentSchool","")}
                            onChange={props.handleChange}/>
                            <bs.Form.Text id="SchoolNameHelpBlock" muted>
                                School name must be three separate words and captilized
                            </bs.Form.Text>
                    </bs.Form.Group>
                    <bs.Form.Group controlId="ResidentTeam">
                        <bs.Form.Label>Team</bs.Form.Label>
                        <bs.Form.Control 
                            as="select" 
                            required
                            name="ResidentTeam"
                            value={props.getState("ResidentTeam", "")}
                            onChange={props.handleChange}
                            >
                            <option></option>
                            {TeamOptions.map(item => {
                                return(
                                    <option value={item.key}>{item.label}</option>)})}
                        </bs.Form.Control>
                    </bs.Form.Group>
                    <bs.Form.Group controlId="ResidentReference">
                            <bs.Form.Label>How did you hear about this team?</bs.Form.Label>
                            <bs.Form.Control
                                as="select"
                                name="ResidentReference"
                                required
                                value={props.getState("ResidentReference", "")}
                                onChange={props.handleChange}>
                                <option></option>
                                {ReferenceOptions.map(item => {
                                    return(
                                        <option value={item.key}>{item.label}</option>)})}
                            </bs.Form.Control>
                        </bs.Form.Group>
                <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                    <bs.Col className="col-3">
                        <bs.Button type="primary" className="NextButton" onClick={props.prev} style={{ float: 'left' }}>
                            &#8592; Back
                        </bs.Button>
                    </bs.Col>
                    <bs.Col/>
                    <bs.Col className="col-6">
                        <bs.Button type="primary" className="NextButton" style={{ float: 'right' }}>
                            Next &#8594;
                        </bs.Button>
                    </bs.Col>
                </bs.Form.Row>
            </bs.Form>
        </bs.Container>
    )
}