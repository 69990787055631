import * as bs from 'react-bootstrap';
import {React, useState} from "react";

export default function Visitor(props) {
    const [validated, setValidated] = useState(false);

    const [CheckBoxvalue, setRadioValue] = useState(props.getState("RoboticsTeam",""));

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
      props.setState("userType","Visitor")
      props.setState("RoboticsTeam",CheckBoxvalue)

      props.next()
    };
  
    return (
        <div>
            <bs.Container>
                <ul className="list-unstyled multi-steps">
                    <li key="aboutMe">About Me</li>
                    <li key="contact" onClick={props.prev} style={{cursor: 'pointer'}}>Contact</li>
                    <li key="otherInfo" className="is-active">Other Info</li>
                    <li key="waiver">Waiver</li>
                    <li key="confirm">Confirm</li>
                </ul>
                <bs.Form validated={validated} onSubmit={handleSubmit}>
                    <h5>Visitor</h5>
                        <bs.Form.Group controlId="VisitorPurpose">
                            <bs.Form.Label>Purpose of Visit</bs.Form.Label>
                            <bs.Form.Control
                                as="select"
                                required
                                name="VisitorPurpose"
                                value={props.getState("VisitorPurpose", "")}
                                onChange={props.handleChange}
                                >
                                <option></option>
                                <option value="Competition">Competition</option>
                                <option value="Meet with Staff">Meet with coach/administrator</option>
                                <option value="See Student">See my student</option>
                                <option value="Other">Other</option>
                            </bs.Form.Control>
                        </bs.Form.Group>
                        <bs.Form.Group controlId="VisitorConsideration">
                        <bs.Form.Text inline id="VisitorConsiderationText">
                              Are you considering joining a robotics team?:&nbsp;&nbsp;&nbsp;&nbsp;  
                                <bs.Form.Check
                                type="checkbox" 
                                label="Yes" 
                                value="Yes"
                                checked ={CheckBoxvalue === "Yes"}
                                onChange={(e) => setRadioValue(e.currentTarget.value)}
                                inline 
                                name="VisitorConsideration"/>   
                                </bs.Form.Text>  
                            </bs.Form.Group>
                        <h8>For Guardians </h8>
                        <i style={{fontSize: "0.75rem"}}>(If you are not a parent/guardian, please leave blank)</i>
                        <hr></hr>
                        <bs.Form.Group controlId="VisitorChildName">
                            <bs.Form.Label>Child's Name</bs.Form.Label>
                            <bs.Form.Control
                                type="text"
                                placeholder="Child's Name"
                                name="VisitorChildName"
                                maxLength = "30"
                                minLength = "5" 
                                value={props.getState("VisitorChildName", "")}
                                onChange={props.handleChange}/>
                        </bs.Form.Group>
                        <bs.Form.Group controlId="VisitorChildEmail">
                            <bs.Form.Label>Child's Email</bs.Form.Label>
                            <bs.Form.Control
                                type="email" 
                                placeholder="Child's Email" //jdoe@atlantapublicschools.us
                                pattern = "^\S+@\S+\.\S+$"
                                name="VisitorChildEmail"
                                value={props.getState("VisitorChildEmail", "")}
                                onChange={props.handleChange}
                                >
                            </bs.Form.Control>
                        </bs.Form.Group>
                    <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                        <bs.Col className="col-3">
                            <bs.Button type="primary" className="NextButton" onClick={props.prev} style={{ float: 'left' }}>
                                &#8592; Back
                            </bs.Button>
                        </bs.Col>
                        <bs.Col/>
                        <bs.Col className="col-6">
                            <bs.Button type="primary" className="NextButton" style={{ float: 'right' }}>
                                Next &#8594;
                            </bs.Button>
                        </bs.Col>
                    </bs.Form.Row>
                </bs.Form>
            </bs.Container>
        </div>
    )
}