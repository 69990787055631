import * as bs from 'react-bootstrap';
import { Link } from "react-router-dom";
import {React} from "react";
import firebase from '../Firebase.js';

function LogIn() {

    const handleSubmit = () => {

        var email = document.getElementById('Email').value;
        var password = document.getElementById('Password').value;

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                //Signed in
                var user = userCredential.user;
                console.log("Successfully signed in")
                console.log(user.uid)
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("Code: " + errorCode)
                console.log(errorMessage)
            })
    };
    
    return(
        <div>
            <bs.Form>
                <div className = "LogIn">
                    <h3>Welcome to Johnson STEM!</h3>
                    <h6>Please log in to access this page!</h6>
                </div>
                    <bs.Form.Group controlId="Email">
                        <bs.Form.Label>Email</bs.Form.Label>
                        <bs.Form.Control type="email" placeholder="Email..." required/>
                    </bs.Form.Group>
                    <bs.Form.Group controlId="Password">
                        <bs.Form.Label>Password</bs.Form.Label>
                        <bs.Form.Control type="password" placeholder="" required/>
                    </bs.Form.Group>
                <Link to ="/">
                    <p className = "MakeanAccount">Make an account</p>
                </Link>
                <Link to ="/ResetPassword">
                    <p className = "ForgotPassword">Forgot Password?</p>
                </Link>
                <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                    <bs.Col className="col-12">
                        <bs.Button variant="primary" onClick={handleSubmit} className="NextButton" >
                            Sign In
                        </bs.Button>
                    </bs.Col>
                </bs.Form.Row>
            </bs.Form>
        </div>
        )  
    };

export default (LogIn)
