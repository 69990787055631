import * as bs from 'react-bootstrap';
import {React, useState} from "react";
import moment from 'moment';
import firebase from '../../Firebase.js';

export default function AboutMe(props) {
    const [validated, setValidated] = useState(false);

      const handleSubmit = (event) => {
          event.preventDefault();
        
        const ConfirmPassword = props.state.ConfirmPassword;
        const Password = props.state.Password;
        const form = event.currentTarget;

        var db = firebase.firestore();
        var users = db.collection("users");
        const email = props.state.Email;
        var docRef = users.doc(email);
        var userExists = false;
        docRef.get().then((doc) => {
            if (doc.exists) {
                userExists = true;
            }
        })
        .then(() => {

            if (userExists === true) {
                console.log("Email Exists")
                alert("An account with that email address has already been created. Please log in or use a different email address")
                return false;
            } else if (Password !== ConfirmPassword) {
                console.log("Passwords don't match")
                alert("Password and Confirm Password fields do not match. Please try again.");
                return false;
            } else {

                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }  
                setValidated(true);

                console.log("Passwords Match")
                props.next()
            }
        })

        return false;
    }

    return (
        <div>
            <bs.Container>
                <ul className="list-unstyled multi-steps">
                    <li key="aboutMe" className="is-active">About Me</li>
                    <li key="contact">Contact</li>
                    <li key="otherInfo">Other Info</li>
                    <li key="waiver">Waiver</li>
                    <li key="confirm">Confirm</li>
                </ul>
                <bs.Form id= "myform" validated={validated} onSubmit={handleSubmit}>
                    <br></br>
                    <h4>Login Information</h4>
                    <p>Used to Check In and Out of JSAC</p>
                    <hr></hr>
                    <bs.Form.Group controlId="Email">
                        <bs.Form.Label>Email Address</bs.Form.Label>
                        <bs.Form.Control 
                            type="email" 
                            name="Email"
                            placeholder="Email" 
                            pattern = "^\S+@\S+\.\S+$"
                            required
                            value={props.getState("Email", "")}
                            onChange={props.handleChange}/>
                    </bs.Form.Group>
                    <bs.Form.Group controlId="Password">
                        <bs.Form.Label>Password</bs.Form.Label>
                        <bs.Form.Control 
                            type="password" 
                            name="Password"
                            placeholder="Password" 
                            maxLength = "25"
                            minLength = "8"
                            pattern = "^(?!.* )(?=.*\d)(?=.*[A-Z]).{8,25}$"
                            required
                            value={props.getState("Password", "")}
                            onChange={props.handleChange}
                            autoComplete="on"/>
                            <bs.Form.Text id="passwordHelpBlock" muted>
                                Your password must be 8-25 characters long, contain one uppercase letter, one number, and
                                must not contain spaces or emoji.
                            </bs.Form.Text>
                    </bs.Form.Group>
                    <bs.Form.Group controlId="ConfirmPassword">
                        <bs.Form.Label>Confirm Password</bs.Form.Label>
                        <bs.Form.Control 
                            type="password" 
                            name="ConfirmPassword"
                            placeholder="Confirm Password" 
                            maxLength = "25"
                            minLength = "8"
                            pattern = "^(?!.* )(?=.*\d)(?=.*[A-Z]).{8,25}$"
                            required
                            value={props.getState("ConfirmPassword", "")}
                            onChange={props.handleChange}
                            autoComplete="on"/>
                    </bs.Form.Group>
                    <br></br>
                    <h4>About You</h4>
                    <hr></hr>
                    <bs.Form.Row>
                        <bs.Col>
                            <bs.Form.Group controlId="FirstName">
                                <bs.Form.Label>First Name</bs.Form.Label>
                                <bs.Form.Control 
                                    type="text" 
                                    name="FirstName"
                                    placeholder="First" 
                                    maxLength = "20"
                                    minLength = "2" 
                                    pattern = "^[A-Z][A-z-'\p{L}\p{M}]+$" //First letter capital. Only letters, hyphens, and apostraphes. And international letters
                                    required 
                                    value={props.getState("FirstName", "")}
                                    onChange={props.handleChange}/>
                            <bs.Form.Text id="FirstNameHelpBlock" muted>
                                First name must be captilized.
                            </bs.Form.Text>
                            </bs.Form.Group>
                        </bs.Col>
                        <bs.Col>
                            <bs.Form.Group controlId="LastName">
                                <bs.Form.Label>Last Name</bs.Form.Label>
                                <bs.Form.Control                                
                                    type="text" 
                                    name="LastName"
                                    placeholder="Last"
                                    maxLength = "30"
                                    minLength = "2" 
                                    pattern = "^[A-Z][A-z-'\p{L}\p{M}]+$" //First letter capital. Only letters, hyphens, and apostraphes. And international letters
                                    required 
                                    value={props.getState("LastName", "")}
                                    onChange={props.handleChange}/>
                                <bs.Form.Text id="LastNameHelpBlock" muted>
                                Last name must be captilized.
                            </bs.Form.Text>
                            </bs.Form.Group>
                        </bs.Col>
                    </bs.Form.Row>
                    <bs.Form.Row> 
                        <bs.Col>
                                <bs.Form.Group controlId="Birthdate">
                                    <bs.Form.Label>Birthdate</bs.Form.Label>
                                    <bs.Form.Control 
                                        type="date" 
                                        name="Birthdate"
                                        placeholder="Birthdate" 
                                        min={moment().subtract(110, 'years').format("YYYY-MM-DD")}  //Must be younger than 110 years old
                                        max={moment().subtract(5, 'years').format("YYYY-MM-DD")}    //Must be older than 5 years old
                                        required
                                        value={props.getState("Birthdate", "")}
                                        onChange={props.handleChange}/>
                                </bs.Form.Group>
                        </bs.Col>
                        <bs.Col>
                            <bs.Form.Group controlId="Gender">
                                <bs.Form.Label>Gender</bs.Form.Label>
                                <bs.Form.Control 
                                    as="select" 
                                    name="Gender"
                                    required
                                    value={props.getState("Gender", "")}
                                    onChange={props.handleChange}>
                                        <option></option>
                                        <option value="Female">Female</option>
                                        <option value="Male">Male</option>
                                        <option value="Other">Other</option>
                                        <option value="NA">Prefer not to say</option>
                                </bs.Form.Control>
                            </bs.Form.Group>
                        </bs.Col>
                    </bs.Form.Row> 
                    <bs.Form.Group controlId="Ethnicity">
                        <bs.Form.Label>Race/Ethnicity</bs.Form.Label>
                        <bs.Form.Control 
                                    as="select" 
                                    name="Ethnicity"
                                    required
                                    value={props.getState("Ethnicity", "")}
                                    onChange={props.handleChange}>
                                        <option></option>
                                        <option value="American Indian/Alaska Native">American Indian or Alaska Native</option>
                                        <option value="Asian">Asian</option>
                                        <option value="Black/African American">Black or African American</option>
                                        <option value="Caucasian/White">Caucasian/White</option>
                                        <option value="Hispanic/Latino">Hispanic/Latino</option>
                                        <option value="Hawaiian/Pacific Islander">Native Hawaiian or Pacific Islander</option>
                                        <option value="Other">Other</option>
                                        <option value="Prefer Not To Say">Prefer not to say</option>
                                </bs.Form.Control>
                    </bs.Form.Group>
                    <bs.Form.Row align="center" style={{'textAlign': 'center'}}>
                        <bs.Col className="col-3"/>
                        <bs.Col/>
                        <bs.Col className="col-6">
                            <bs.Button type="submit" className="NextButton" style={{ float: 'right' }}>
                                Next &#8594;
                            </bs.Button>
                        </bs.Col>
                    </bs.Form.Row>
                </bs.Form>
            </bs.Container>
        </div>
    )
}