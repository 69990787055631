import * as bs from 'react-bootstrap';
import DifferenceButton from '../DifferencePopper';
import {React} from "react";
import {Link } from "react-router-dom";

export default function SignUpOptions() {
    return(
        <div className="buttonDiv">
            <Link to={{pathname: '/signup', state: {userType: 'Resident'}}}>
                <bs.Button size="lg">Resident</bs.Button>
            </Link>
            <Link to={{pathname: '/signup', state: {userType: 'Camp Student'}}}>
                <bs.Button size="lg">Camp Student</bs.Button><br></br>
            </Link>
            <Link to={{pathname: '/signup', state: {userType: 'Coding Student'}}}>
                <bs.Button size="lg">Coding Student</bs.Button><br></br>
            </Link>
            <Link to={{pathname: '/signup', state: {userType: 'Staff'}}}>
                <bs.Button size="lg">Staff</bs.Button><br></br>
            </Link>
            <Link to={{pathname: '/signup', state: {userType: 'Visitor'}}}>
                <bs.Button size="lg">Visitor</bs.Button><br></br>
            </Link>
            <DifferenceButton/>
        </div>
    )
}