import './App.scss';
import { Header , SignUpOptions , CheckIn, CheckInSuccess, CheckOutSuccess, SignUp, SignUpSuccess, LogIn, ResetPassword } from "./components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as bs from 'react-bootstrap';
// import { Redirect } from 'react-router-dom';

export default function App() {

  return (
    <div className="App">
      <Header />
      <br></br>
        <bs.Container>
            <bs.Row className="justify-content-md-center">
              <div className="col-2" style={{color: "white"}}></div>
              <div className="col-8 col align-self-center">
                <Router>
                  <Switch>
                    <Route path="/" exact render={() => <SignUpOptions />} />
                    <Route path="/CheckIn" exact render={(props) => <CheckIn {...props} />} />
                    <Route path="/SignUp" exact render={(props) => <SignUp {...props} />} >
                    {/* {usertype ? <Redirect to="/" /> : <SignUpOptions />} */}
                    </Route>
                    <Route path="/SignUpSuccess" exact render={(props) => <SignUpSuccess {...props} />} />
                    <Route path="/CheckInSuccess" exact render={(props) => <CheckInSuccess {...props} />} />
                    <Route path="/CheckOutSuccess" exact render={(props) => <CheckOutSuccess {...props} />} />
                    <Route path="/LogIn" exact render={(props) => <LogIn {...props} />} />
                    <Route path="/ResetPassword" exact render={(props) => <ResetPassword {...props} />} />
                  </Switch>
                </Router>
              </div>
              <div className="col-2" style={{color: "white"}}></div>
            </bs.Row>
        </bs.Container>
    </div>
  );
}
