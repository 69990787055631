import robot from '../../JSAC robot-01 1.png';

export default function SignUpSuccess() {
    // setTimeout(function(){
    //     window.location.href = 'https://www.johnsonstem.org/';
    //  }, 3000);
    return(
        <div>
            <br></br>
            <div className = "CheckInCheckOut">
                <h3>Welcome!</h3>
                <h6>Thanks for signing up!</h6>
            </div>
            <br></br>
            <img
             src= {robot}
             width="400"
             alt="jstem robot"
         />{' '}
        </div>

        )  
}