import * as bs from 'react-bootstrap';
import {React, useState} from "react";

export default function Staff(props) {
    const [validated, setValidated] = useState(false);
    const PositionOptions=[
        {label: "Coach", key: "Coach"}
      , {label: "Operations Manager", key: "Operations Manager"}
      , {label: "Development Manager", key: "Development Manager"}
      , {label: "Volunteer", key: "Volunteer"}
      , {label: "Parent", key: "Parent"}
      , {label: "Teacher", key: "Teacher"}
      , {label: "Marketing Manager", key: "Marketing Manager"}
      , {label: "Other", key: "Other"}
  ];

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
  
      setValidated(true);
      props.setState("userType","Staff")
      props.next()
    };
  
    return (
        <div>
            <bs.Container>
                <ul className="list-unstyled multi-steps">
                    <li key="aboutMe">About Me</li>
                    <li key="contact" onClick={props.prev} style={{cursor: 'pointer'}}>Contact</li>
                    <li key="otherInfo" className="is-active">Other Info</li>
                    <li key="waiver">Waiver</li>
                    <li key="confirm">Confirm</li>
                </ul>
                <bs.Form validated={validated} onSubmit={handleSubmit}>
                    <h5>Staff</h5>
                        <bs.Form.Group controlId="StaffPosition">
                            <bs.Form.Label>Position</bs.Form.Label>
                            <bs.Form.Control
                                as="select"
                                name="StaffPosition"
                                placeholder="Staff Position"
                                required
                                value={props.getState("StaffPosition", "")}
                                onChange={props.handleChange}>
                                    <option></option>
                                    {PositionOptions.map(item => {
                                        return(
                                            <option value={item.key}>{item.label}</option>)})}
                            </bs.Form.Control>
                        </bs.Form.Group>
                    <bs.Form.Row align="center" style={{textAlign: 'center'}}>
                        <bs.Col className="col-3">
                            <bs.Button type="primary" className="NextButton" onClick={props.prev} style={{ float: 'left' }}>
                                &#8592; Back
                            </bs.Button>
                        </bs.Col>
                        <bs.Col/>
                        <bs.Col className="col-6">
                            <bs.Button type="primary" className="NextButton" style={{ float: 'right' }}>
                                Next &#8594;
                            </bs.Button>
                        </bs.Col>
                    </bs.Form.Row>
                </bs.Form>
            </bs.Container>
        </div>
    )
}