import * as bs from 'react-bootstrap';
import {React, useState} from "react";
import firebase from '../../Firebase.js';
import { Redirect } from "react-router-dom";

export default function ConfirmInfo(props) {

    var email = props.state.Email;
    var password = props.state.Password;
    const [shouldRedirect, setShouldRedirect] = useState(false);
    // console.log('userType: ', props.state.userType);
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        var adultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
        var BirthdateParts = props.state.Birthdate.split('-');
        var Birthdate = new Date(BirthdateParts[0], BirthdateParts[1] - 1, BirthdateParts[2]);

        var WaiverAdultParts = props.state.WaiverAdultDate.split('-');
        var WaiverAdultDate = new Date(WaiverAdultParts[0], WaiverAdultParts[1]-1,WaiverAdultParts[2]);

        if (Birthdate > adultDate) {
            var WaiverChildParts = props.state.WaiverChildDate.split('-');
            var WaiverChildDate = new Date(WaiverChildParts[0], WaiverChildParts[1]-1,WaiverChildParts[2]);
        }
            
        var promise = firebase.auth().createUserWithEmailAndPassword(email,password);
        promise.catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log(errorCode)
            console.log(errorMessage)
            // ..
        });

        promise.then(function () {
            var userUid = firebase.auth().currentUser.uid;
            var rtdb = firebase.database();
            var db = firebase.firestore();
            var created = new Date();

            db.collection("users").doc(props.state.Email).set({
                createdDate: created.toLocaleString("en-US"),
                userType: props.state.userType,
                userUid: userUid,
                firstName: props.state.FirstName,
                lastName: props.state.LastName,
                email: props.state.Email,
                birthDate: Birthdate.toLocaleDateString("en-US"),
                gender: props.state.Gender,
                ethnicity: props.state.Ethnicity,
                address1: props.state.Address1,
                addressCity: props.getState("City", "Atlanta"),
                addressState: props.getState("State", "GA"),
                addressZipCode: props.state.ZipCode,
                phone: props.state.Phone,
                emergencyContactFirstName: props.state.EmergencyContactFirstName,
                emergencyContactLastName: props.state.EmergencyContactLastName,
                emergencyContactEmail: props.state.EmergencyContactEmail,
                emergencyContactPhone: props.state.EmergencyContactPhone,
                emergencyContactRelationship: props.state.EmergencyContactRelationship,
                checkedIn: false,
                waiverAdultName: props.state.WaiverAdultName,
                waiverAdultDate: WaiverAdultDate.toLocaleDateString("en-US"),
                waiverAdultCity: props.state.WaiverAdultCity,
                waiverAdultAccept: props.state.WaiverAdultAccept
            })

            Birthdate > adultDate &&
            
                db.collection("users").doc(props.state.Email).set({
                    waiverChildName: props.state.WaiverChildName,
                    waiverChildDate: WaiverChildDate,
                    waiverChildCity: props.state.WaiverChildCity,
                    waiverChildAccept: props.state.WaiverChildAccept,
                }, { merge: true });
            
            props.state.Address2 !== undefined &&
                db.collection("users").doc(props.state.Email).set({
                    address2: props.state.Address2,
                }, { merge: true });

            props.state.userType === 'Camp Student' &&
                db.collection("users").doc(props.state.Email).set({
                    currentSchool: props.state.CampStudentCurrentSchool,
                    campSeason: props.state.CampSeason
                }, { merge: true });
            
            props.state.userType === 'Coding Student' &&
                db.collection("users").doc(props.state.Email).set({
                    currentSchool: props.state.CodingStudentCurrentSchool,
                    codingClass: props.state.codingClass
                }, { merge: true });

            props.state.userType === 'Staff' &&
                db.collection("users").doc(props.state.Email).set({
                    position: props.state.StaffPosition
                }, { merge: true });
            
            props.state.userType === 'Visitor' &&
                db.collection("users").doc(props.state.Email).set({
                    visitorSchool: props.state.VisitorSchool,
                    visitorPurpose: props.state.VisitorPurpose,
                    visitorChildName: props.state.VisitorChildName,
                    visitorChildEmail: props.state.VisitorChildEmail
                }, { merge: true })

            rtdb.ref('users/' + userUid).set({
                createdDate: created.toLocaleString("en-US"),
                userType: props.state.userType,
                userUid: userUid,
                firstName: props.state.FirstName,
                lastName: props.state.LastName,
                email: props.state.Email,
                birthDate: Birthdate.toLocaleDateString("en-US"),
                gender: props.state.Gender,
                ethnicity: props.state.Ethnicity,
                address1: props.state.Address1,
                addressCity: props.getState("City", "Atlanta"),
                addressState: props.getState("State", "GA"),
                addressZipCode: props.state.ZipCode,
                phone: props.state.Phone,
                emergencyContactFirstName: props.state.EmergencyContactFirstName,
                emergencyContactLastName: props.state.EmergencyContactLastName,
                emergencyContactEmail: props.state.EmergencyContactEmail,
                emergencyContactPhone: props.state.EmergencyContactPhone,
                emergencyContactRelationship: props.state.EmergencyContactRelationship,
                checkedIn: false,
                waiverAdultName: props.state.WaiverAdultName,
                waiverAdultDate: WaiverAdultDate.toLocaleDateString(),
                waiverAdultCity: props.state.WaiverAdultCity,
                waiverAdultAccept: props.state.WaiverAdultAccept
            })


            Birthdate > adultDate &&
            
                rtdb.ref('users/' + userUid).update({
                    waiverChildName: props.state.WaiverChildName,
                    waiverChildDate: WaiverChildDate.toLocaleDateString(),
                    waiverChildCity: props.state.WaiverChildCity,
                    waiverChildAccept: props.state.WaiverChildAccept,
                });
            
            props.state.Address2 !== undefined &&
                rtdb.ref('users/' + userUid).update({
                    address2: props.state.Address2,
                });
            
            props.state.userType === 'Resident' &&
                rtdb.ref('users/' + userUid).update({
                    currentSchool: props.state.ResidentCurrentSchool,
                    team: props.state.ResidentTeam,
                    residentReference: props.state.ResidentReference,
                });

            props.state.userType === 'Camp Student' &&
                rtdb.ref('users/' + userUid).update({
                    currentSchool: props.state.CampStudentCurrentSchool,
                    campReference: props.state.CampStudentReference,
                    campSeason: props.state.CampSeason
                });
            
            props.state.userType === 'Coding Student' &&
                rtdb.ref('users/' + userUid).update({
                    currentSchool: props.state.CodingStudentCurrentSchool,
                    codingClass: props.state.codingClass
                });

            props.state.userType === 'Staff' &&
                rtdb.ref('users/' + userUid).update({
                    position: props.state.StaffPosition
                });
            
            props.state.userType === 'Visitor' &&
                rtdb.ref('users/' + userUid).update({
                    visitorSchool: props.state.VisitorSchool,
                    visitorPurpose: props.state.VisitorPurpose,
                    visitorChildName: props.state.VisitorChildName,
                    visitorChildEmail: props.state.VisitorChildEmail
                })

            .then(() => {
                console.log("Document successfully written!");
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
        })

        setShouldRedirect(true);
      };
    return(
        
        <div>
            <bs.Container>
                <ul className="list-unstyled multi-steps">
                    <li key="aboutMe">About Me</li>
                    <li key="contact">Contact</li>
                    <li key="otherInfo">Other Info</li>
                    <li key="waiver" onClick={props.prev} style={{cursor: 'pointer'}}>Waiver</li>
                    <li key="confirm" className="is-active">Confirm</li>
                </ul>
                <br></br>
                <h2>Make sure all information below is correct:</h2>
                <br></br>

                <bs.Card>
                    <bs.Card.Body>
                        <h5>About Me</h5>
                        <bs.Row>
                            <bs.Col><p>First Name: {props.state.FirstName}</p></bs.Col>
                            <bs.Col><p>Email: {props.state.Email}</p></bs.Col>
                            <bs.Col><p>Gender: {props.state.Gender}</p></bs.Col>
                        </bs.Row>
                        <bs.Row>
                            <bs.Col><p>Last Name: {props.state.LastName}</p></bs.Col>
                            <bs.Col><p>Birthdate: {props.state.Birthdate}</p></bs.Col>
                            <bs.Col><p>Ethnicity: {props.state.Ethnicity}</p></bs.Col>
                        </bs.Row>
                    </bs.Card.Body>
                </bs.Card>
                <br></br>
                <bs.Card>
                    <bs.Card.Body>
                        <h5>Contact Info</h5>
                        <bs.Row>
                            <bs.Col><p>Address: {props.state.Address1}</p></bs.Col>
                            <bs.Col><p>City: {props.getState("City", "Atlanta")}</p></bs.Col>
                            <bs.Col><p>Zip Code: {props.state.ZipCode}</p></bs.Col>
                        </bs.Row>
                        <bs.Row>
                            <bs.Col><p>Address Continued: {props.state.Address2}</p></bs.Col>
                            <bs.Col><p>State: {props.getState("State", "GA")}</p></bs.Col>
                            <bs.Col><p>Phone: {props.state.Phone}</p></bs.Col>
                        </bs.Row>
                    </bs.Card.Body>
                </bs.Card>
                <br></br>
                <bs.Card>
                    <bs.Card.Body>
                        <h5>Emergency Contact</h5>
                        <bs.Row>
                            <bs.Col><p>First Name: {props.state.EmergencyContactFirstName}</p></bs.Col>
                            <bs.Col><p>Email: {props.state.EmergencyContactEmail}</p></bs.Col>
                            <bs.Col><p>Relationship to Student: {props.state.EmergencyContactRelationship}</p></bs.Col>
                        </bs.Row>
                        <bs.Row>
                            <bs.Col><p>Last Name: {props.state.EmergencyContactLastName}</p></bs.Col>
                            <bs.Col><p>Phone: {props.state.EmergencyContactPhone}</p></bs.Col>
                            <bs.Col></bs.Col>
                        </bs.Row>
                    </bs.Card.Body>
                </bs.Card>
                <br></br>
                <bs.Card>
                    <bs.Card.Body>
                    <h5>Additional Info</h5>
                    {props.state.userType === "Resident" &&
                        <div>
                            <bs.Row>
                                <bs.Col>
                                    <p>Current School: {props.state.ResidentCurrentSchool}</p>
                                </bs.Col>
                            </bs.Row>
                            <bs.Row>
                                <bs.Col>
                                    <p>Team: {props.state.ResidentTeam}</p>
                                </bs.Col>
                            </bs.Row>
                    </div>
                    }
                    {props.state.userType === "Camp Student" &&
                        <div>
                            <bs.Row>
                                <bs.Col>
                                <p>Current School: {props.state.CampStudentCurrentSchool}</p>
                                </bs.Col>
                            </bs.Row>
                            <bs.Row>
                                <bs.Col>
                                    <p>Camp Season: {props.state.CampSeason}</p>
                                </bs.Col>
                            </bs.Row>
                        </div>
                    }

                    {props.state.userType === "Coding Student" &&
                        <div>
                            <bs.Row>
                                <bs.Col>
                                    <p>Current School: {props.state.CodingStudentCurrentSchool}</p>
                                </bs.Col>
                            </bs.Row>
                            <bs.Row>
                                <bs.Col>
                                    <p>How did you hear about us: {props.state.CampStudentReference}</p>
                                </bs.Col>
                            </bs.Row>
                            <bs.Row>
                                <bs.Col>
                                    <p>Coding Class: {props.state.CodingClass}</p>
                                </bs.Col>
                            </bs.Row>
                        </div>
                    }

                    {props.state.userType === "Staff" &&
                        <div>
                            <bs.Row>
                                <bs.Col>
                                    <p>Position: {props.state.StaffPosition}</p>
                                </bs.Col>
                            </bs.Row>
                            
                        </div>
                    }

                    {props.state.userType === "Visitor" &&
                        <div>
                            <bs.Row>
                                <bs.Col>
                                <p>Current School: {props.state.VisitorSchool}</p>
                                </bs.Col>
                                <bs.Col>
                                <p>Child's Name: {props.state.VisitorChildName}</p>
                                </bs.Col>   
                            </bs.Row>
                            <bs.Row>
                                <bs.Col>
                                <p>Visit Purpose: {props.state.VisitorPurpose}</p>
                                </bs.Col>
                                <bs.Col>
                                <p>Child's Email: {props.state.VisitorChildEmail}</p>
                                </bs.Col>
                            </bs.Row>
                        </div>
                    }
                    </bs.Card.Body>
                </bs.Card>

                <br></br>
                
                <bs.Form.Row align="center" style={{'textAlign': 'center'}}>
                    <bs.Col className="col-3">
                        <bs.Button type="primary" className="NextButton" onClick={props.prev} style={{ float: 'left' }}>
                            &#8592; Back
                        </bs.Button>
                    </bs.Col>
                    <bs.Col/>
                    <bs.Col className="col-6">
                        <bs.Button type="submit" className="NextButton" onClick={handleSubmit} style={{ float: 'right' }}>
                            Submit &#8594;
                        </bs.Button>
                    </bs.Col>
                </bs.Form.Row>
            </bs.Container>
            {shouldRedirect && (
                <Redirect push to={'/SignUpSuccess'}/>
            )}
        </div>
    )
}